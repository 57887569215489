var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section is-primary pb-1 pt-1"},[_c('div',{staticClass:"container has-text-centered",class:_vm.printing ? 'has-background-white' : 'has-background-white-ter',staticStyle:{"max-height":"100%"}},[_c('div',{staticClass:"box",staticStyle:{"margin":"auto","text-align":"center"}},[_c('strong',{staticClass:"is-small-caps"},[_vm._v(_vm._s(_vm.myForm?.myForm?.form?.formTitle))])]),(_vm.myForm)?_c('section',{staticClass:"section is-primary pb-1 pt-1 scroller",staticStyle:{"font-size":"large"}},[(!_vm.printing)?_c('router-link',{staticClass:"button",attrs:{"target":"_blank","to":{
          path:
            '/forms/id/' +
            _vm.$route.params.formID +
            '/submission/' +
            this.$route.params.subDate +
            '/' +
            this.$route.params.submissionID +
            '/print',
        }}},[_vm._v("Print")]):_vm._e(),_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.dateSubmitted))]),_c('p',[_vm._v(_vm._s(_vm.myForm.values.firstname)+" "+_vm._s(_vm.myForm.values.lastname))]),(_vm.myForm.submittedBy)?_c('p',[_vm._v("by "+_vm._s(_vm.myForm.submittedBy))]):_vm._e(),_vm._l((_vm.myForm.myForm.form.formFieldGroups),function(group,iKey){return _c('div',{key:iKey,staticClass:"pb:2em mt:1em"},[_c('hr',{staticStyle:{"background-color":"black","height":"1px","max-width":"80%","margin":"auto","margin-bottom":"2em"}}),(group.richText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.expand(group.richText.content))}}):_vm._e(),_vm._l((group.fields),function(f,ii){return _c('div',{key:ii},[_c('formFieldViewer',{attrs:{"fieldData":f,"value":_vm.myValues[f.name],"previousFields":_vm.myValues,"index":_vm.myCurrentSection}}),_vm._l((f.dependentFieldFilters),function(x,y){return _c('div',{key:y},[(_vm.showDependentField(f.name, x, _vm.myCurrentSection))?_c('formFieldViewer',{attrs:{"fieldData":x.dependentFormField,"value":_vm.myValues[x.dependentFormField.name],"previousFields":_vm.myValues,"index":_vm.myCurrentSection}},[_vm._v(_vm._s(x)+" ")]):_vm._e()],1)})],2)})],2)})],2):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticClass:"is-small-caps"},[_vm._v("Submitted")])])
}]

export { render, staticRenderFns }