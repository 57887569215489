<template>

    <div class="field">
        <label class="label">{{ expand(myFieldData.label) }}</label>
        <div v-if="['text', 'date', 'textarea', 'email', 'phone'].includes(myFieldData.fieldType)" class="control ul-box">
            <p>{{ myValue || '-'}} </p>
        </div>

        <div v-else-if="myFieldData.fieldType  ==  'select'" class="control ul-box">
            <p>{{ getSelectorDisplay || "-" }}</p>

        </div>
        <div v-else-if="myFieldData.fieldType  ==  'checkbox' && getCheckBoxSelected" class="control ul-box" >
            <ul>
                <li v-for="(opt, jj) in getCheckBoxSelected" :key="jj">
                    <label class="checkbox">
                        <input type="checkbox"  checked disabled />
                        {{ opt.label  }}
                    </label>
                </li>
            </ul>

        </div>


        <div v-else-if="myFieldData.fieldType  ==  'displayText'" >
            <p>{{ expand(myFieldData.displayText) }}</p>
        </div>
        <div v-else>
            <p>{{ myFieldData }}</p>
        </div>
        <p class="help" v-if="myFieldData.description">{{ expand(myFieldData.description) }}</p>

        <div v-if="myFieldData.links?.length" style="padding-top: 4px;">
            <p class="is-1" style="font-weight: bold; font-size:medium;">External Links</p>
            <ul>
                <li v-for="l in myFieldData.links" :key="l.link" style="margin-left: 2em;">
                    {{ l.name || "External Link" }} - {{ l.link }}
                </li>
            </ul>
        </div>

    </div>

</template>
<script>


import functions from "helpers/functions.js"

export default {
    name: 'formField',
    props: ["fieldData", "index", "value", "previousFields"],
    data: function () {
        return {
            myValue: this.value,
            errorMsg: false,
            avoid_changes: 0,
            myFieldData: this.fieldData,
        }
    },

    mounted() {


    },

    computed: {

        getSelectorDisplay() {
            let x =  this.myFieldData.options.filter(y => y.value == this.myValue)
            return x[0]?.label
        },
        getCheckBoxSelected() {
            return this.myFieldData.options.filter(y => this.myValue.includes(y.value) )


        }


    },



    methods: {

        expand(text) {
            console.log({ text, x:this.previousFields})
            return functions.expand(text, this.previousFields)
        }




    }


}
</script>

<style>
div.ul-box {
    float: none;
    border: thin;
    border-style: solid;
    padding: 5px;
}


div.ul-box.is-danger {
    border-color: red;
}



div.ul-box.is-success {
    border-color: green;
}
</style>