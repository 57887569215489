export default {
  convertToMinutes(time) {
    let hours = parseInt(time[0], 10)
    let mins = parseInt(time[1], 10)

    return mins + (hours * 60)
  },

  groupID(d) {
    return decodeURI(d).split("/").filter(x=>x)
  },

  expand(text, lookups) {

    if ((text ?? "").trim().length === 0) {
        return text;
    }

    console.log("IN expand.......", text, lookups)


    const regex = /\{([^}]+)\}/g;

    let x = text.matchAll(regex)

    const matches = [...new Set([...x].map(x =>  x[1])) ];

    console.log(matches)

    for (const match of matches) {

      if (lookups[match] === undefined) {
        continue
      }

        console.log(match)

        text = text.replaceAll('{'+match+'}', lookups[match] ?? "")

    }

    console.log({ text })


    return text

},

}




