<template>
  <section class="section is-primary pb-1 pt-1">
    <div
      class="container has-text-centered"
      :class="printing ? 'has-background-white' : 'has-background-white-ter'"
      style="max-height: 100%"
    >
      <div class="box" style="margin: auto; text-align: center">
        <strong class="is-small-caps">{{
          myForm?.myForm?.form?.formTitle
        }}</strong>
      </div>
      <section
        v-if="myForm"
        class="section is-primary pb-1 pt-1 scroller"
        style="font-size: large"
      >
        <router-link
          class="button"
          v-if="!printing"
          target="_blank"
          :to="{
            path:
              '/forms/id/' +
              $route.params.formID +
              '/submission/' +
              this.$route.params.subDate +
              '/' +
              this.$route.params.submissionID +
              '/print',
          }"
          >Print</router-link
        >

        <p><strong class="is-small-caps">Submitted</strong></p>
        <p>{{ dateSubmitted }}</p>
        <p>{{ myForm.values.firstname }} {{ myForm.values.lastname }}</p>
        <p v-if="myForm.submittedBy">by {{ myForm.submittedBy }}</p>

        <div
          v-for="(group, iKey) in myForm.myForm.form.formFieldGroups"
          :key="iKey"
          class="pb:2em mt:1em"
        >
          <hr
            style="
              background-color: black;
              height: 1px;
              max-width: 80%;
              margin: auto;
              margin-bottom: 2em;
            "
          />

          <div
            v-if="group.richText"
            v-html="expand(group.richText.content)"
          ></div>
          <div v-for="(f, ii) in group.fields" :key="ii">
            <formFieldViewer
              :fieldData="f"
              :value="myValues[f.name]"
              :previousFields="myValues"
              :index="myCurrentSection"
            />

            <div v-for="(x, y) in f.dependentFieldFilters" :key="y">
              <formFieldViewer
                :fieldData="x.dependentFormField"
                :value="myValues[x.dependentFormField.name]"
                :previousFields="myValues"
                :index="myCurrentSection"
                v-if="showDependentField(f.name, x, myCurrentSection)"
                >{{ x }}
              </formFieldViewer>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
//import formSchema from "@/forms/registration.js"
//import formField from "@/components/form/field.vue"
import formFieldViewer from "@/components/form/fieldview.vue";
//import { format } from "date-fns"
//import { toast } from 'bulma-toast'

import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import download from "downloadjs";

import functions from "helpers/functions.js";

//

export default {
  name: "xyz",

  components: {
    formFieldViewer,
  },

  data() {
    return {
      myForm: null,
      myValues: {},
      mySectionsOk: {},
      mySectionsFieldsOk: {},
      myCurrentSection: this.$getConst("FORMS_HUMAN_CHECK"),
      loading: false,
      savedValues: {},
      iAmHuman: false,
      isARestart: false,
      formID: null,
      digest: null,
      submitting: false,
      submissionReference: null,
      introduction: "",
      printing: this.$route.params.action == "print",
    };
  },
  async mounted() {
    console.log("Hello");

    this.formID = this.$route.params.formID;

    this.$api
      .get(
        "v2/staff/forms/" +
          this.$route.params.formID +
          "/instance/" +
          this.$route.params.subDate +
          "/" +
          this.$route.params.submissionID,
        true,
        true
      )
      .then((response) => {
        this.myForm = response;
        this.myValues = response.values;
      });
  },
  created() {},

  computed: {
    group() {
      return this.myForm?.formFieldGroups?.[this.myCurrentSection] ?? [];
    },
    sectionOK() {
      console.log(
        "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
      );
      console.log({
        section: this.myCurrentSection,
        value: this.mySectionsOk[this.myCurrentSection.toString()],
      });
      return this.mySectionsOk[this.myCurrentSection.toString()];
    },
    total() {
      return this.myForm.formFieldGroups.length;
    },

    dateSubmitted() {
      if (!this.myForm.timestamp) return "";

      let y = parseISO(this.myForm.timestamp);

      return format(y, "do LLL yyyy HH:mm");
    },
  },
  methods: {
    async printSlips(orders, date, menu) {
      const pdfDoc = await PDFDocument.create();
      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.Courier);

      let allergensImg = {};
      for (let option of orders) {
        const page = pdfDoc.addPage([62, 100]);
        const { width, height } = page.getSize();
        const title = option[0].toUpperCase();

        page.drawLine({
          start: { x: 2, y: height - 3 },
          end: { x: width - 2, y: height - 3 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });

        page.drawLine({
          start: { x: 2, y: 3 },
          end: { x: width - 2, y: 3 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });

        page.drawLine({
          start: { x: 2, y: height - 3 },
          end: { x: 2, y: 3 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });

        page.drawLine({
          start: { x: width - 2, y: height - 3 },
          end: { x: width - 2, y: 3 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });

        let start = height - 3;

        this.centreText(page, title, start, 4, timesRomanFont, 10);
        start -= 11;
        this.centreText(page, date, start, 3, timesRomanFont, 8);
        start -= 9;
        this.centreText(
          page,
          option[3].toUpperCase(),
          23,
          3,
          timesRomanFont,
          8
        );
        this.centreText(
          page,
          option[2].toUpperCase(),
          13,
          3,
          timesRomanFont,
          8
        );

        let allergens = {};

        for (let option2 of menu["options"][option[0]]["options"]) {
          let subtitle = option2["title"].toUpperCase();

          this.centreText(page, subtitle, start, 3, timesRomanFont, 6);

          start = start - 6.5;

          if (option2.allergen) {
            option2.allergen.forEach((element) => {
              allergens[element] = 1;
            });
          }

          for (let option3 of option2.choose) {
            console.log([option3]);

            let option3a = this.getOptionDescription(option3);
            let o = this.getOptionAllergens(option3);

            console.log([o]);

            if (
              option[1].includes(option2["title"] + "_#_" + option3) ||
              option[1].includes(option2["title"] + "_#_" + option3a)
            ) {
              this.centreText(page, option3a, start, 3, timesRomanFont, 4);

              if (o) {
                o.forEach((element) => {
                  allergens[element] = 1;
                });
              }

              start = start - 4.5;
            }
          }
        }

        let z = Object.keys(allergens);
        console.log(z);

        if (z.length > 0) {
          let x = 3 + (58 - 11 * z.length) / 2;

          for (let element of z) {
            console.log(element);

            if (!allergensImg[element]) {
              let res = await fetch(
                process.env.BASE_URL + "img/icons/allergen/" + element + ".png"
              );
              console.log(res);
              let arrayBuffer = await res.arrayBuffer();
              console.log(arrayBuffer);
              allergensImg[element] = await pdfDoc.embedPng(arrayBuffer);
            }

            page.drawImage(allergensImg[element], {
              x: x,
              y: 21,
              width: 10,
              height: 10,
            });
            x = x + 11;
          }
        }
      }
      pdfDoc.save().then((blob) => {
        download(
          blob,
          "lunchOrder-" + format(new Date(), "yyyy-MM dd HH:mm:ss") + ".pdf",
          "application/pdf"
        );
      });
    },

    expand(text) {
      console.log({ text, x: this.myValues });
      return functions.expand(text, this.myValues);
    },

    showDependentField(currentField, dependentField, _index) {
      let myValue = this.myValues[currentField];

      for (let filter of dependentField.filters) {
        if (Array.isArray(myValue) === false) {
          myValue = [myValue];
        }

        for (let j of myValue) {
          if (filter.strValue === j) {
            return true;
          }
          if (filter.boolValue === j) {
            return true;
          }

          if (filter.numberValue === j) {
            return true;
          }

          if (filter.strValues.includes(j)) {
            return true;
          }

          if (filter.numberValues.includes(j)) {
            return true;
          }
        }
      }

      //console.log({ currentField, currentValue: this.myValues[currentField], dependentField })
      //this.clearFieldError(index, dependentField.dependentFormField.name, true)
      return false;
    },
  },
};
</script>

<style scoped>
.scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroller {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
